<template>
  <div class="aboutQk">
    <img-div height="400" :img-url="require('@/assets/aboutQk/mask.png')" />
    <div class="container qkProduct">
      <div class="itemTitle">{{ $t("aboutQk.componeyName") }}</div>
      <div class="desc1 mt28">
        {{ $t("aboutQk.componeyDesc1") }}
        <br />
        <br />
      </div>
      <div class="desc1">
        {{ $t("aboutQk.componeyDesc2") }}
        <br />
        <br />
      </div>
      <div class="desc1">
        {{ $t("aboutQk.componeyDesc3") }}
      </div>
      <el-image
        :src="require(`@/assets/aboutQk/tree.png`)"
        fit="scale-down"
        class="treeImg"
      ></el-image>
    </div>
    <div class="advantage">
      <div class="container">
        <div class="itemTitle">{{ $t("aboutQk.advantageName") }}</div>
        <!-- <div class="desc1 mt28">
          {{ $t("aboutQk.advantageDesc") }}
        </div> -->
        <div class="desc1 init-indent mt53">
          <div class="desc1-title">
            {{ $t("aboutQk.advantageListTitle1") }}
          </div>
          <div class="mt12">
            {{ $t("aboutQk.advantageListDesc1") }}
          </div>
        </div>
        <div class="desc1 init-indent mt53">
          <div class="desc1-title">{{ $t("aboutQk.advantageListTitle2") }}</div>
          <div class="mt12">
            {{ $t("aboutQk.advantageListDesc2") }}
          </div>
        </div>
        <div class="desc1 init-indent mt53">
          <div class="desc1-title">{{ $t("aboutQk.advantageListTitle3") }}</div>
          <div class="mt12">
            {{ $t("aboutQk.advantageListDesc3") }}
          </div>
        </div>
        <div class="desc1 init-indent mt53">
          <div class="desc1-title">{{ $t("aboutQk.advantageListTitle4") }}</div>
          <div class="mt12">
            {{ $t("aboutQk.advantageListDesc4") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
// import TreeItem from "@/components/business/TreeItem";
export default {
  components: {
    ImgDiv,
    // TreeItem,
  },
  data() {
    return {
      treeData: [
        {
          name: "1",
          child: [
            { name: "2", child: [{ name: "1" }, { name: "2" }] },
            { name: "1", child: [{ name: "1" }, { name: "2" }] },
          ],
        },
      ],
    };
  },
  computed: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.aboutQk {
  background-color: var(--whiteColor);
  // padding-bottom: 100px;
  .qkProduct {
    padding-bottom: 100px;
  }
  .desc1 {
    color: var(--blackColor);
  }
  .treeImg {
    width: 1150px;
    margin: 74px auto 0;
    display: block;
  }
  .advantage {
    background-color: var(--bgGray);
    padding-bottom: 100px;
    .itemTitle {
      margin-top: 0;
      padding-top: 85px;
    }
  }
}
</style>
