import { render, staticRenderFns } from "./aboutQk.vue?vue&type=template&id=37af9628&scoped=true"
import script from "./aboutQk.vue?vue&type=script&lang=js"
export * from "./aboutQk.vue?vue&type=script&lang=js"
import style0 from "./aboutQk.vue?vue&type=style&index=0&id=37af9628&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37af9628",
  null
  
)

export default component.exports